<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <div class="table-content">
            <div class="title">{{data.course.course_name || name}}-结果评价</div>
            <div class="item" style="flex: 1;">
              <div class="text">总结评论:</div>
              <div class="cont">{{data.course.evaluate||'暂无'}}</div>
            </div>
            <div class="item">
              <div class="text">章节评价:</div>
              <div class="cont" style="flex: 1;">
                <template v-if="data.unit">
                  {{data.unit.evaluate}}
                </template>
                <template v-if="data.list.length > 0">
                  <div v-for="item,i in data.list" :key="i" style="white-space: pre-wrap;">
                    {{item.evaluate}}
                  </div>
                </template>
                <template v-else>
                  暂无
                </template>
              </div>
            </div>
            <div class="item">
              <div class="text">祝福语:</div>
              <div class="cont" style="flex: 1;">{{data.course.blessing||'暂无'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { classEvaluate } from "@/api/ladder.js";
export default {
  components: {},
  props:['study_user_id','course_id'],
  data() {
    return {
      name:"",
      data:{course:'',unit:""}
    };
  },
  mounted() {
    this.name=this.$route.query.name  
    this.getComment();
  },
  methods: {
    // 获取评语
    getComment() {
      classEvaluate({ course_id:this.course_id,study_user_id:this.study_user_id,teach_id:parseInt(this.$route.query.tid || 0) })
        .then((res) => {
          this.data=res.data
          this.name=(res.data.course || {}).course_name ||this.name;
        })
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    width: 100%;

    .table-content {
      padding: 1rem;
      background-color: #fff;
      .title {
        text-align: center;
        font-size: 26px;
        margin: 40px 0;
      }
      .item {
        margin-top: 30px;
        .text {
          margin-right: 10px;
        }
        display: flex;
        padding: 20px;
        border: 1px solid #ccc;
        min-height: 300px;
        width: 100%;
        font-size: 18px;
      }
    }
  }
}
</style>
